import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css'; // Chemin vers le fichier CSS
import liveIcon from '../../assets/TikTokLive.png'; // Chemin correct vers l'image

function Home() {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      {/* Conteneur pour l'image */}
      <div className="image-wrapper">
        <img src={liveIcon} alt="TikTok Live" className="slide-fwd-bottom" />
      </div>
      
      {/* Conteneur pour le texte */}
      <div className="text-wrapper">
        <h1 className="title-text">Bienvenue sur le live automatique !</h1>
        <p className="explanatory-text">
          Cette application vous permet d'animer automatiquement votre live tout en augmentant vos revenus. Il vous suffit de configurer les paramètres via le bouton "Paramètres", puis de diffuser votre écran en streaming. N'oubliez pas de lancer votre jeu avant de commencer !
        </p>
      </div>
      
      {/* Conteneur pour les boutons */}
      <div className="buttons-wrapper">
        <div className="button-container">
          <button className="navigate-button" onClick={() => navigate('/Game')}>
            Game
          </button>
        </div>
        <div className="button-container">
          <button className="navigate-button" onClick={() => navigate('/settings')}>
            Paramètres
          </button>
        </div>
        <div className="button-container">
          <button className="navigate-button" onClick={() => navigate('/feedback')}>
            Feedback
          </button>
        </div>
        <div className="button-container">
          <button className="navigate-button" onClick={() => navigate('/credit')}>
            Credit
          </button>
        </div>
      </div>
      
      {/* Texte "demo v2" en bas à droite */}
      <div className="demo-text">
        demo v2
      </div>
    </div>
  );
}

export default Home;
