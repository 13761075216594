import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Game.css';

function App() {
  const navigate = useNavigate();

  // Configuration par défaut
  const defaultConfig = {
    time: 300,
    bleu: 'Phonk',
    rouge: 'Techno',
    scoreBleu: 1,
    scoreRouge: 1,
  };

  // Chargement des paramètres depuis le localStorage ou des valeurs par défaut
  const phunkScore = parseFloat(localStorage.getItem('config-scoreBleu')) || defaultConfig.scoreBleu;
  const technoScore = parseFloat(localStorage.getItem('config-scoreRouge')) || defaultConfig.scoreRouge;
  const valeur1 = localStorage.getItem('config-bleu') || defaultConfig.bleu;
  const valeur2 = localStorage.getItem('config-rouge') || defaultConfig.rouge;
  const temps = parseFloat(localStorage.getItem('config-time')) || defaultConfig.time;

  const [timeLeft, setTimeLeft] = useState(temps);
  const [phunkImages, setPhunkImages] = useState([]);
  const [technoImages, setTechnoImages] = useState([]);
  const [currentPagePhunk, setCurrentPagePhunk] = useState(0);
  const [currentPageTechno, setCurrentPageTechno] = useState(0);

  const imagesPerPage = 9;

  // Charger les images depuis l'API en fonction des IDs stockés
  useEffect(() => {
    const loadGiftImages = async (giftIds, setImages) => {
      try {
        const imageUrls = await Promise.all(
          giftIds.map(async (id) => {
            const response = await axios.get(`http://localhost:3001/gift?id=${id}`);
            return response.data.imageUrl;
          })
        );
        setImages(imageUrls);
      } catch (error) {
        console.error("Erreur lors du chargement des icônes:", error);
      }
    };

    const phunkGiftIds = JSON.parse(localStorage.getItem('selectedBlueGifts')) || [];
    const technoGiftIds = JSON.parse(localStorage.getItem('selectedRedGifts')) || [];

    loadGiftImages(phunkGiftIds, setPhunkImages);
    loadGiftImages(technoGiftIds, setTechnoImages);
  }, []);

  // Logique du compte à rebours
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Changement automatique des images toutes les 5 secondes
  useEffect(() => {
    const autoChange = setInterval(() => {
      setCurrentPagePhunk((prev) => (prev + 1) % Math.ceil(phunkImages.length / imagesPerPage));
      setCurrentPageTechno((prev) => (prev + 1) % Math.ceil(technoImages.length / imagesPerPage));
    }, 5000);

    return () => clearInterval(autoChange);
  }, [phunkImages.length, technoImages.length]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const countdownClass = timeLeft <= 15 ? 'countdown-timer blinking' : 'countdown-timer';

  return (
    <div className="container">
      {/* Bouton pour accéder aux paramètres */}
      <button className="settings-button" onClick={() => navigate('/settings')}>Settings</button>
      <button className="home-button" onClick={() => navigate('/')}>Home</button>

      {/* Sections d'affichage des équipes */}
      <div className="side phunk top"><h1>{valeur1}</h1></div>
      <div className="side techno top"><h1>{valeur2}</h1></div>

      {/* Section de temps et de jauge */}
      <div className="middle">
        <div className={countdownClass}><h2>{formatTime(timeLeft)}</h2></div>
        <div className="jauge-container">
          <div className="jauge phunk" style={{ width: `${(phunkScore / (phunkScore + technoScore)) * 100}%` }}>
            <div className="percentage-label left">{((phunkScore / (phunkScore + technoScore)) * 100).toFixed(1)}%</div>
          </div>
          <div className="jauge techno" style={{ width: `${(technoScore / (phunkScore + technoScore)) * 100}%` }}>
            <div className="percentage-label right">{((technoScore / (phunkScore + technoScore)) * 100).toFixed(1)}%</div>
          </div>
        </div>
      </div>

      {/* Carrousel d'images Phunk */}
      <div className="carousel phunk">
        <div className="carousel-images">
          {phunkImages.slice(currentPagePhunk * imagesPerPage, (currentPagePhunk + 1) * imagesPerPage).map((image, index) => (
            <img key={index} src={image} alt={`phunk-${index}`} />
          ))}
        </div>
      </div>

      {/* Carrousel d'images Techno */}
      <div className="carousel techno">
        <div className="carousel-images">
          {technoImages.slice(currentPageTechno * imagesPerPage, (currentPageTechno + 1) * imagesPerPage).map((image, index) => (
            <img key={index} src={image} alt={`techno-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
