import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Credit.css'; // Chemin vers le fichier CSS
import logo from '../../assets/coeur.png'; // Importez l'image téléchargée

function Credit() {
    const navigate = useNavigate();
  
    return (
      <div className="credit-container">
        {/* Logo en haut avec l'animation */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo-image slide-fwd-bottom" />
        </div>
        
        {/* Conteneur pour le texte */}
        <div className="text-wrapper">
          <h1 className="title-text">Crédits</h1>
          <p className="explanatory-text">
            Cette application a été développée par une équipe dévouée.<br /> Nous tenons à remercier :
          </p>
          <ul className="credits-list">
            <li><strong>Gabriel SADOC</strong> - Développeur Frontend</li>
            <li><strong>Michael DE RAYMOND CAHUSAC</strong> - Développeur Backend</li>
            <li><strong>Frédéric OLALDE</strong> - Directeur Général</li>
          </ul>
        </div>
  
        {/* Conteneur pour le bouton de retour */}
        <div className="buttons-wrapper">
          <div className="button-container">
            <button className="navigate-button" onClick={() => navigate('/')}>
              Retour à l'accueil
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Credit;
