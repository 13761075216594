import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Feedback.css';
import logo from '../../assets/evaluation.png'; // Assurez-vous que le chemin est correct

function Retour() {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [rating, setRating] = useState(0); // État pour stocker l'évaluation par étoiles
  const [hoverRating, setHoverRating] = useState(0); // État pour gérer le survol des étoiles

  // Fonction pour générer une "adresse IP" fictive ou un identifiant
  const generateIdentifier = () => {
    return `ID-${Math.random().toString(36).substring(2, 15)}`; // Génère un identifiant aléatoire
  };

  const starRating = (rating) => {
    return '<:etoile:1284180656712056853>'.repeat(rating) + '<:etoilevide:1284180504471404544>'.repeat(5 - rating); // Génère des étoiles pleines et vides
  };

  const sendWebhook = () => {
    const webhookUrl = 'https://discord.com/api/webhooks/1283507345418092605/YaBZNrpyzM7J1_NLNdelG1qMuxKpH9jPaDCfBR07aTLqHQoHTU8b3JeQNmIN12gIJG-L';

    const payload = {
      embeds: [
        {
          title: "<:neko_Prime:1267840207588429966> Nouveau Message de Retour",
          description: "Nouveau messsage :",
          fields: [
            {
              name: "Évaluation :",
              value: starRating(rating), // Utilisation des étoiles dans le champ
              inline: true
            },
            {
              name: "Message :",
              value: message,
              inline: true
            },
            {
              name: "Identifiant :",
              value: generateIdentifier(), // Utilisation de l'identifiant généré
              inline: true
            }
          ],
          color: 0xf48db1, // Couleur du bordure de l'embed (en hexadécimal)
          footer: {
            text: "Envoyé depuis l'application",
            icon_url: "https://i.postimg.cc/Jnt45Z4W/image-2024-08-26-205034675.png" // Image pour le footer
          },
          thumbnail: {
            url: "https://i.postimg.cc/Jnt45Z4W/image-2024-08-26-205034675.png" // Image pour le thumbnail
          },
          timestamp: new Date().toISOString() // Ajoute l'horodatage
        }
      ]
    };

    axios.post(webhookUrl, payload)
      .then(() => {
        setConfirmation('Message et évaluation envoyés avec succès ! ' + generateIdentifier());
        setMessage('');
        setRating(0); // Réinitialise l'évaluation
      })
      .catch(() => {
        setConfirmation('Erreur lors de l\'envoi du message.');
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendWebhook();
    }
  };

  useEffect(() => {
    if (confirmation) {
      const timer = setTimeout(() => {
        setConfirmation('');
      }, 4000);

      return () => clearTimeout(timer); // Nettoie le timeout si le composant est démonté
    }
  }, [confirmation]);

  return (
    <div className="retour-container">
      <div className="image-wrapper">
        <img src={logo} alt="Logo" className="slide-fwd-bottom" />
      </div>

      <h1 className="title-text">Retour</h1>
      <p className="explanatory-text">
      Entrez un message et sélectionnez une note pour l'envoyer à l'équipe de développement.
      Les retours sont anonymes, si vous voulez que l'on vous recontacte, mentionnez le !
      </p>

      <div className="input-container">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Entrez votre message"
          className="input-message"
        />
      </div>

      {/* Système d'évaluation par étoiles */}
      <div className="rating-wrapper">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${(hoverRating || rating) >= star ? 'filled' : ''}`} // Remplissage selon survol ou sélection
            onClick={() => setRating(star)}
            onMouseEnter={() => setHoverRating(star)} // Définit l'état survolé
            onMouseLeave={() => setHoverRating(0)} // Réinitialise l'état survolé
          >
            ★
          </span>
        ))}
      </div>

      {confirmation && <p className="confirmation-text">{confirmation}</p>}

      <div className="buttons-wrapper">
        <div className="button-container">
          <button className="send-button" onClick={sendWebhook}>
            Envoyer
          </button>
        </div>
        <div className="button-container">
          <button className="navigate-button" onClick={() => navigate('/')}>
            Retour à l'accueil
          </button>
        </div>
      </div>
    </div>
  );
}

export default Retour;
